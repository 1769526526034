.selector-content {
    width: 100%;
    height: fit-content;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    padding: 15px 0 10px 15px;
    margin: 10px 0 10px auto;
}

.selector-container {
    width: 100%; 
    height: auto;
    display: flex;
    justify-content: flex-end;
}

.selector-container-options {
    width: 85%; 
    background: #0d98c7;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px 0 10px 10px;
    margin-right: 10px;
}

.selector-options {
    margin-right: -5px;
}

.selector-options label {
    background: #0d7ea3;
    width: 90%;
    box-sizing: border-box;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Quicksand';
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    padding: 5px 5px 5px 10px;
    justify-content: center;
}

.selector-options label:hover {
    /* background: #914a53; */
    background: #06526b;
}

.selector-container img {
    height: 30px;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

@media only screen and (min-width: 768px) {

    .selector-container-options {
        width: 25%;
    }

    .selector-options label {
        width: 91%;
        text-align: center;
    }

}