.home-octopus-container {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-octopus-content {
    width: 85%;
    height: 100%;
    position: absolute;
    margin: auto;
}

.home-octopus-greeting {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
}

.home-octopus-greeting label {
    margin-bottom: 10px;
    text-align: center;
}