.chatbot-chat-container {
    width: 100%;
    height: 100vh;
    overflow: scrol;
}

.chatbot-chat-content h1 {
    margin-bottom: 0px;
    padding-top: 10px;
    font-size: 30px;
    text-align: center;
}

.chatbot-chat-content {
    width: 85%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    color: #fff;
}

.chatbot-chat {
    width: 100%;
    height: 70vh;
    background:white;
    margin: auto;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.16);
    padding: 20px 20px 10px 20px;
    box-sizing: border-box;
    opacity: 0.9;
}

.chatbot-chat-container-body {
    width: 100%;
    height: 90%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.chatbot-chat-container-input {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 768px) {

    .chatbot-chat {
        height: 500px;
    }

    .chatbot-chat-container {
        width: 80%;
        margin: auto;
    }

    .chatbot-chat-content h1 {
        /* margin-bottom: -50px; */
        margin-top: 100px;
        font-size: 40px;
    }
}