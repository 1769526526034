.octopus-item-container {
    display: flex;
    width: auto;
    /* align-items: center; */
}

.octopus-item-container img {
    height: 35px;
    padding-top: 1px;
}

.octopus-item-messages {
    display: flex;
    flex-direction: column;
    width: 85%;
    align-items: flex-start;
}

.octopus-item-container label {
    margin: 0 0 5px 10px;
    font-size: 14px;
    color: #ffffff;
    background: rgb(56, 102, 189);
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    font-weight: normal;
    box-sizing: border-box;
}

.octopus-item-container a:visited {
    color: #fff;
    font-weight: bold;
}

.octopus-item-container a:hover {
    font-weight: bolder;
}

@media only screen and (min-width: 768px) {
    .octopus-item-messages {
        width: 100%;
    }
}