.user-item-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.user-item-container label {
    font-size: 14px;
    color: #ffffff;
    /* background: #159ed4; */
    background: #0d98c7;
    border-radius: 5px;
    width: fit-content;
    font-weight: bold;
    box-sizing: border-box;
    padding: 10px;
    margin: 0 10px 5px 0;
}

.user-item-container img {
    height: 30px;
}