.octopus-container {
    width: 100%;
    height: 50vh;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    .octopus-container {
        height: 70vh;
    }
}